import device from 'bui/util/device'

export function isLandscape(){
    return (window.outerWidth>=900 && window.outerHeight >=600) || window.outerWidth >= 700 
}

export function visibleBounds(){
    let b = this.getBounds()

    let mapLeft = b._sw.lng
    let mapRight = b._ne.lng
    let mapTop = b._ne.lat
    let mapBottom = b._sw.lat

    let xOffset = .4
    let yOffset = .65

    if( isLandscape () ){
        yOffset = 0
    }else{
        xOffset = 0
    }
    
    let mapRightViewable = mapRight + ((mapLeft - mapRight) * xOffset)
    let mapBottomViewable = mapBottom + ((mapTop - mapBottom) * yOffset)

    return [mapLeft, mapRightViewable, mapTop, mapBottomViewable]
}

export function flyTo(lng, lat, zoom){

    // zoom first before making calculations
    if( zoom )
        this.zoomTo(zoom, {animate: false})

    let b = this.getBounds()
    let center = this.getCenter()

    let mapLeft = b._sw.lng
    let mapRight = b._ne.lng
    let mapTop = b._ne.lat
    let mapBottom = b._sw.lat

    let xOffset = .4
    let yOffset = .70

    if( isLandscape () ){
        yOffset = 0
    }else{
        xOffset = 0
    }
    
    let mapRightViewable = mapRight + ((mapLeft - mapRight) * xOffset)
    let mapBottomViewable = mapBottom + ((mapTop - mapBottom) * yOffset)
    
    let yMax = Math.max(mapTop, mapBottomViewable)
    let yMin = Math.min(mapTop, mapBottomViewable)

    let xMax = Math.max(mapLeft, mapRightViewable)
    let xMin = Math.min(mapLeft, mapRightViewable)

    if( lng > xMin && lng < xMax){
        lng = center.lng
    }
        
    if( lat > yMin && lat < yMax){
        lat = center.lat
    }else if( yOffset ) {
        // move to top viewable area
        lat = lat - ((mapTop - mapBottom) * .35)
    }
    
    let args = {
        center: [lng, lat],
        duration: 0,
    }

    if( zoom != undefined )
        args.zoom = zoom
        
    this.flyTo(args);

    return
}

export function flyToIfNeeded(lat){

    // how much of map is still visible
    let viewHeight = 1 - .65

    let b = this.getBounds()
    let bottom = b._sw.lat
    let top = b._ne.lat
    let h = b._ne.lat - b._sw.lat
    let markerTop = b._ne.lat - lat

    let percent = markerTop / h

    if( percent > viewHeight ){

        lat = lat - ((viewHeight/2) * h)
        
        // let add = (70 - percent) / 100 * h
        // console.log(bottom, lat, top);

        this.flyTo({
            center: [this.getCenter().lng, lat],
            duration: 0,
        });
    }
}