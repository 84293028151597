import { LitElement, html, css } from 'lit'
import {repeat} from 'lit/directives/repeat.js';
import Panel from 'panel'
import 'panel/fullscreen-btn'
import RoutedView from 'bui/app/views/routed'
import device from 'bui/util/device'
import {mediaQuery} from 'bui/util/mediaQueries'
import 'bui/elements/text'
import 'bui/elements/grid'
import Weather from '../weather'
import '../weather/forecast'
import '../snotel/data'
import features, {Feature} from '../map/models'
import favorites from '../map/favorites'
import 'bui/elements/copy-btn'
import './feature'
import {isLandscape} from '../map/util'

window.favorites = favorites // TEMP

// (min-width:900px) and (min-height: 600px), (min-width: 700px)

// https://www.wcc.nrcs.usda.gov/siteimages/479.jpg

Panel.register('detail/(:type@):id(,:id2)', 'm-detail', {
    // controller: device.isSmall ? 'root': 'map',
    anchor: !isLandscape() ? 'bottom' : 'right',
    height: !isLandscape() ? '65vh' : '100%',
    width: !isLandscape() ? '100%' : '600px',
    title: 'Detail',
    className: 'detail',
    closeBtn: 'arrow'
})

let ts = new Date().getTime()

customElements.defineShared('m-detail', class extends RoutedView{

    static get styles(){return css`
        :host {
            display: block;
            position:relative;
            width: 400px;
            max-width: 100%;
            /* min-height: 300px; */
            /* max-height: 30vh; */
            display: grid;
            grid-template-rows: auto 1fr;
            height: 100%;
            overflow: hidden;
        }
    
        main {
            padding: var(--gutter);
            padding-bottom: var(--gutter-safe-bottom);
            overflow: auto;
            padding-top: .5em;
        }

        .cams img {
            width: 100%;
            border-radius: 4px;
        }

        ${mediaQuery('b-app-landscape', css`
            [slot="close-btn"] {
                transform: none;
            }
        `)}

        m-detail-feature:not([type="snotel"]) {
            margin: 0 var(--gutter-negative);
        }

        [fav] {
            color: var(--amber-700);
        }
    `}

    willOpen(){ 
        this.panel.anchor = !isLandscape() ? 'bottom' : 'right'
        this.panel.height = !isLandscape() ? '65vh' : '100%'
        this.panel.width = !isLandscape() ? '100%' : '600px'

        // return mapView.loaded
    }

    onClose(){
        super.onClose(...arguments)
        window.detailView = null
    }

    onOpen(...args){
        window.detailView = this // so map can close it
    }

    get coll(){ return features }
    modelRequired = true
    loadFetchData = 'with=children'

    async load(id, attrs={}, state){
        
        if( state.params.type == 'weather'){
            this.model = new Feature({
                type: state.params.type,
                lng: state.params.id,
                lat: state.params.id2
            })
        }else{
            return super.load(id, attrs, state)
        }
    }

    disconnectedCallback(){ 
        super.disconnectedCallback()
        this.model = null
    }

    onModelChange(){

        if( !this.model ) return

        // if we just loaded the app for the first time, zoom to this feature
        if( (new Date().getTime() - ts) < 1000)
            window.mapView?.map.setZoom(10)

        this.panel.title = this.model.get('name')
        setTimeout(()=>{
            this.flyTo()
        })        
        
    }

    // async _load(id, attrs, props){
        
    //     if( isLandscape() ){
    //         window.mapView?.map.setPadding({
    //             right: 500,
    //             bottom: 0
    //         })
    //     }else{
    //         window.mapView?.map.setPadding({
    //             right: 0,
    //             bottom: Math.round(window.outerHeight * .6)
    //         })
    //     }

    
        
    //     this.anchor = isLandscape() ? 'bottom' : 'right'
    //     this.height = isLandscape() ? '65vh' : '100%'
    //     this.width = isLandscape() ? '100%' : '600px'

    //     let {type} = props.params

    //     if( type == 'weather' ){
    //         this.model = {
    //             type: 'weather',
    //             longitude: props.params.id,
    //             latitude: props.params.id2
    //         }
    //         this.panel.title = 'Weather'
    //         this.flyTo()
    //         return
    //     }

    //     let feature = await fetch.json(`/api/snotel/geojson/${type}@${id}`)

    //     if( !feature ){
    //         this.close()
    //         throw new UIWarningError('Could not find that feature')
    //     }

    //     this.feature = feature
    //     this.model = feature.properties
    //     this.panel.title = this.model.name || this.model.title
    //     this.flyTo()

    // }

    flyTo(){
        if( window.mapView?.map ){
            mapView.loaded.then(e=>{
                mapView?.flyTo(this.model.lng, this.model.lat)
            })
        }
    }

    onWeatherLoaded(e){
        if( this.model.get('type') == 'weather' ){
            this.panel.title = 'Weather: '+e.detail.location
        }
    }

    toggleFavorite(){
        
        let route = this.panel.route
        let {type, id, id2} = route.params
        let path = route.currentPath

        if( favorites.get(path) )
            favorites.delete(path)
        else
            favorites.set(path, {type, lng: id, lat: id2})

        this.update()
    }

    get isFavorite(){
        return favorites.get(this.panel.route.currentPath)
    }

    share(){}

    render(){return html`
        
        <b-panel-toolbar>

            <b-flex right slot="right" gap="0" style="min-width: max-content;">

            ${device.isInstalled?html`
                <b-copy-btn _slot="right" text icon="export" label="Share url" .value=${location.href}></b-copy-btn>
            `:''}
            
            ${this.model?.get('type')=='weather'?html`
                <b-btn _slot="right" text icon="star" ?fav=${this.isFavorite} @click=${this.toggleFavorite}></b-btn>
            `:''}

            ${device.isSmall?html`
                <b-panel-fullscreen-btn .panel=${this.panel} slot="" text></b-panel-fullscreen-btn>    
            `:''}

            </b-flex>
        </b-panel-toolbar>
        
        <main>
            
            ${this.model?html`
            <m-detail-feature .model=${this.model}></m-detail-feature>
            `:''}

            ${this.model?.get('children').map(m=>html`
                <m-detail-feature .model=${m}></m-detail-feature>
            `)}

            <b-hr short thick pad="md" ?hidden=${this.model?.get('type')=='weather'}></b-hr>

            <c-weather-forecast 
                .autoLoad=${this.model?.get('type')!='cam'}
                lng=${this.model?.lng} 
                lat=${this.model?.lat}
                @loaded=${this.onWeatherLoaded}
            ></c-weather-forecast>

        </main>
    `}


})

export default customElements.get('m-detail')