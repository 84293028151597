import { LitElement, html, css } from 'lit'
import { cToF } from 'bui/util/math'
import 'bui/elements/table'
import 'bui/elements/table-row'

customElements.define('m-feature-detail-usgs', class extends LitElement{

    static styles = css`
        :host {
            display: block;
            position:relative;
            padding: 1em;
        }
    `

    onModelChange(model){
        if( model )
            this.load()
    }

    async load(){

        if( !this.model ){
            this.data = null
            return this.requestUpdate()
        }

        this.data = await fetch.json(this.model.url()+'/usgs-latest')
        // this.data.date.map(d=>dayjs(d)) 

        this.data.forEach(d=>{

            let latest = d.value[d.value.length-1]

            d.latest = latest
        })

        this.requestUpdate()
    }

    shouldShow(key){
        return true
        return ['Temperature', 'Streamflow', 'Gage height'].includes(key)
    }

    render(){return html`

        <b-table>
            <b-table-row slot="header">
                <b-text w="2fr" semibold>USGS Sensor</b-text>
                <b-text w="1fr">Value</b-text>
                <b-text w="1fr">Date</b-text>
            </b-table-row>


        
        ${this.data?.map(d=>html`
            <b-table-row gap=" " ?hidden=${!this.shouldShow(d.name)} .model=${d}>
                
                <b-text semibold>
                    <b-text .html=${d.name}></b-text>
                </b-text>
                
                <b-text>
                    ${d.name=='Temperature'?html`
                        <b-text tnum>${cToF(d.latest?.val)}</b-text> <b-text dim>˚F</b-text>
                    `:html`
                        <b-text tnum><b-decimal .num=${d.latest?.val} placeholder="-"></b-decimal></b-text> <b-text dim>${d.unit}</b-text>
                    `}
                </b-text>

                <b-text dim>
                    <b-ts .date=${d.latest?.ts}></b-ts>
                </b-text>

            </b-table-row>
        `)}

        <b-table-row>
            <b-flex colspan>
                <b-text muted sm>ID: ${this.model.get('iid')}</b-text>
                <b-text sm italic link clear color="theme" href="https://waterdata.usgs.gov/monitoring-location/${this.model.get('iid')}" target="_blank">View on USGS.gov</b-text>
            </b-flex>
        </b-table-row>

        </b-table>
    `}

})

export default customElements.get('m-feature-detail-usgs')