import { LitElement, html, css } from 'lit'
import 'bui/helpers/lit/contextmenu'
import 'bui/elements/embed'
import './video-feed'

customElements.define('m-detail-feature', class extends LitElement{

    static styles = css`
        :host {
            display: block;
            position:relative;
        }

        img {
            width: 100%;
            max-width: 100%;
        }
    `

    disconnectedCallback(){ 
        super.disconnectedCallback()
        this.model = null
    }

    updated(){
        this.setAttribute('type', this.model?.get('type'))
    }

    render(){return html`
        
        ${this.model?.get('type')=='snotel'?html`
        <s-snotel .model=${this.model}></s-snotel>
        `:''}

        ${this.model?.get('type')=='cam'&&this.model.isImg?html`
            <img src=${this.model.url()+'/cam'}>
        `:''}

        ${this.model?.get('type')=='cam'&&this.model.isFeed?html`
            <m-detail-feature-video-feed url=${this.model.feedUrl+'?'+new Date().getTime()}>
                <span slot="placeholder">${this.model.get('name')}</span>
            </m-detail-feature-video-feed>
        `:''}

        ${this.model?.get('type')=='cam'&&this.model.get('props')?.embed?html`
            <b-embed url=${this.model.get('props')?.embed}></b-embed>
        `:''}

        ${this.model?.get('type')=='air'?html`
        <b-grid cols=1 gap=".5">
            <b-text align="center" sm ucase muted=2>Air Quality</b-text>
            
            <b-text xl xbold align="center">
                <b-text style="color: ${this.model.airQualityColor}">
                ${this.model.aqiInfo()?.level||'Unknown Air Quality'}:
                </b-text>

                <b-text>
                    ${this.model.get('props')?.AQI} 
                    <b-text muted xs nobold>${this.model.get('props')?.parameter}</b-text>
                </b-text>

            </b-text>
            
            <b-text align="center" italic body>
                ${this.model.aqiInfo()?.detail||''}
                <b-text muted block>as of <b-ts .date=${this.model.airQualityTS}></b-ts></b-text>
            </b-text>

        </b-grid>
        `:''}
    `}

})

export default customElements.get('m-detail-feature')