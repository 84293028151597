import { LitElement, html, css } from 'lit'
import AppInstaller from 'bui/app/installer'

customElements.define('s-about', class extends LitElement{
    
    static get title(){ return 'About' }
    static get path(){ return 'about' }
    static get id(){ return 'about' }
    static get icon(){ return 'info-circled' }

    static get styles(){return css`
        :host {
            display: block;
            position:relative;
            padding: var(--gutter);
            padding-top: var(--gutter-safe-top);
            /* font-size: 20px; */
            line-height: 1.3em;
            height: 100%;
            overflow: auto;
        }

        @media (min-width: 600px) {
            :host {
                font-size: 20px;
                line-height: 1.3em;
            }
        }

        b-icon {
            color: var(--theme);
            font-size: 6em;
        }

        main {
            max-width: 600px;
            margin: 0 auto;
            text-align: center;
        }

        .share {
            position: absolute;
            top: calc(1em + var(--safe-top));
            right: 1em;
        }
    `}

    firstUpdated(){
        AppInstaller.canInstallPromise.then(e=>this.update())
    }

    render(){return html`

        <main>

            <b-btn class="share" clear color="theme" @click=${this.share}>Share</b-btn>

        <header>
            <b-icon name="logo"></b-icon>
            <br><br>
            <b-text block xbold xxl>MountainReport.app</b-text>
        </header>

        ${AppInstaller.canInstall?html`
            <br>
            <b-btn lg color="theme" @click=${AppInstaller.install}>Install App</b-btn>
        `:''}

        <b-hr short thick pad="md"></b-hr>

        <b-text block>A unified app for checking snotel sensors, NOAA weather, road cams, and resort webcams to know when it's time to “shred the gnar” (or plan your next mountain adventure)</b-text>

        <br>

        <b-text block ucase bold>TIP:</b-text>
        <b-text block>Long press anywhere on the map for a weather forecast</b-text>

        <br>

        <b-text block>
            Built by <b-text href="https://kevinjantzer.com">Kevin Jantzer</b-text>
        </b-text>

        <b-text muted italic>A hobby project for my own adventures</b-text>

        <b-hr short thick pad="md"></b-hr>

        <b-text>Have a question, comment, or request?
            <br>Shoot me an email <b-text href="kevin@jantzer.dev?subject=${location.hostname}">kevin@jantzer.dev</b-text></b-text>


        <br><br>
        <b-flex>

            <b-btn color="" outline href="https://cash.app/$kjantzer">☕️ Buy me a coffee</b-btn>

            <b-btn color="" outline @click=${this.reload}>Reload App</b-btn>
            
        </b-flex>

        <b-hr short thick pad="md"></b-hr>

        <b-flex col style="text-align: left">
            <b-text block xbold md>Changelog</b-text>

            <div>
                <b-text block xbold>Possible future features 🤔</b-text>
                <ul>
                    <li>Avalanche data (from https://avalanche.org/)</li>
                </ul>
            </div>

            <div>
                <b-text block xbold>Version 2.7 - Oct 2024</b-text>
                <ul>
                    <li>Oregon USGS stream/lake data</li>
                </ul>
            </div>

            <div>
                <b-text block xbold>Version 2.6 - June 2023</b-text>
                <ul>
                    <li>Air Quality (data from airnow.gov)</li>
                    <li>Features can be hidden</li>
                    <li>New "Reports" feature - curated photos, videos, and general stoke.</li>
                </ul>
            </div>

            <div>
                <b-text block xbold>Version 2.5 - Mar 2023</b-text>
                <ul>
                    <li>Snotel summary data improved (highlights new snow over last 5 days)</li>
                    <li>Snotel outlier data mitigated</li>
                    <li>Live video feeds supported (see Mt Ashland)</li>
                    <li>Support for non-ssl feature cameras (ex: Dead Indian, OR)</li>
                </ul>
            </div>

            <div>
                <b-text block xbold>Version 2.4 - Dec 2022</b-text>
                <ul>
                    <li>Accounts with theme support! 🌞/🌜</li>
                    <li>Improved display of the map markers</li>
                </ul>
            </div>

            <div>
                <b-text block xbold>Version 2.3 - Nov 2022</b-text>
                <ul>
                    <li>Snow depth will show on the marker if the value was retrieved within the last 60 minutes</li>
                    <li>Minor design tweaks for installed desktop version</li>
                    <li>Below freezing weather temps show icicles</li>
                    <li>Backend: changed how features are loaded for future improvements</li>
                </ul>
            </div>

            <div>
                <b-text block xbold>Version 2.2 - Jun 2022</b-text>
                <ul>
                    <li>Rebranded to MountainReport</li>
                </ul>
            </div>

            <div>
                <b-text block xbold>Version 2.1 - Mar 2021</b-text>
                <ul>
                    <li>Highlight snow depth change in snotel summary</li>
                    <li>Show max temp in last 24 hours in snotel summary</li>
                    <li>Snotel 14 day trend auto scrolls to present</li>
                    <li>Improve charts to keep label from clipping at the top</li>
                    <li>Zoom map in when loading from detail url</li>
                </ul>
            </div>

            <div>
                <b-text block xbold>Version 2 - Oct 2021</b-text>
                <ul>
                    <li>Now remembers your map location when reloaded</li>
                    <li>Uses url routing so snotels, cams, and weather details can be shared via url</li>
                    <li>Unified how data details are displayed</li>
                    <li>Added marker clustering to improve performance and allow for more data</li>
                    <li>Added favorites feature for saving locations for weather checks</li>
                    <li>Added hourly weather forecast</li>
                    <li>Added 14 day snotel trend</li>
                    <li>Added snotel historical SWE chart</li>
                    <li>Added all oregon road cams</li>
                    <li>Fixed how forecast is pulled from wheather.gov to improve accuracy</li>
                </ul>
                
            </div>

            <div>
                <b-text block xbold>Version 1 - Nov 2020</b-text>
                <ul>
                    <li>First released into the wild!</li>
                </ul>
            </div>
        </b-flex> 

        </main>
    `}

    reload(){
        location.reload(true)
    }

    async share(){

        // TODO: open prefilled email instead?
        if( !navigator.canShare )
            throw new UIAlertError('Your device does not support the share feature')
        
        const image = await fetch('/img/preview.jpg');
        const blob = await image.blob();
        const file = new File([blob], 'image.jpg', { type: 'image/jpeg' });

        navigator.share({
            title: 'MountainReport.app',
            text: `A unified app for checking snotel sensors, NOAA weather, road cams, and resort webcams to know when it's time to “shred the gnar” (or plan your next mountain adventure)`,
            url: 'https://mountainreport.app',
            files: [file]
        }).catch(err=>{})
    }

})

export default customElements.get('s-about')