import Chart, {Plugins, Ticks, fixOutliers} from '../weather/chart'
import dayjs from 'dayjs'
import { round } from 'bui/util/math'

customElements.define('s-snotel-chart-precip', class extends Chart{

    get options(){return {
        scales: {
            x: {
                ticks: {
                    callback: Ticks.byHour,
                    font: {size: 14},
                    padding: 20
                },
            },
            y: {
                // suggestedMin: 10,
            }
        }
    }}

    get plugins(){ return [
        Plugins.addPointLabels({
            suffix:'',
            format: (value, index, datasetIndex, {ctx, x, y})=>{
                if( datasetIndex == 1 )
                    value = round(value / 2.5, 1)

                return value
            }
        })
    ]}

    get chartData(){
        return {
            labels: this.data.date.map(t=>dayjs(t)),
            datasets: [{
                label: 'Snow Depth (in)',
                data: fixOutliers(this.data['snow_depth'], {iqrLower:3}),
                borderColor: '#00E5FF'
            },{
                label: 'SWE (in)',
                data: fixOutliers(this.data['snow_water_equivalent'], {iqrLower:3}).map(val=>val*2.5),
                borderColor: '#2962FF'
            }]
        }
    }

})
