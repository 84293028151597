import Panel from 'panel'
import ChartPanel, {html} from '../weather/chart-panel'
import './trend-chart-temp'
import './trend-chart-precip'

Panel.register('snotel/trend/:id', 's-snotel-trend', {
    title: 'Snotel Trend',
    anchor: 'bottom',
    height: 'auto',
    disableBackdropClick: false,
    closeOnEsc: true,
    closeBtn: 'arrow'
})

customElements.defineShared('s-snotel-trend', class extends ChartPanel {

    async load(id, attrs, state){
        
        if( state.props.data )
            this.data = state.props.data
        else{
            // let triplet = id.replace(/_/g, ':')
            this.data = await fetch.json(`/api/features/${id}snotel-hourly`)
            this.data.date = this.data.date.map(d=>dayjs(d))
        }

        this.update()

        setTimeout(()=>{
            this.scrollToEnd()    
        })
        
    }

    renderCharts(){ return html`
        <s-snotel-chart-temp .data=${this.data} gutter title="Weather"></s-snotel-chart-temp>
        <s-snotel-chart-precip .data=${this.data} gutter title="Accum. Precip."></s-snotel-chart-precip>
    `}
})

export default customElements.get('s-snotel-trend')