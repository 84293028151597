import { LitElement, html, css } from 'lit'
import device from 'bui/util/device'
// import Snotel from '../snotel/index'
import 'bui/elements/tooltip'
import 'bui/elements/numeral'
import 'bui/elements/label'

customElements.define('m-map-marker', class extends LitElement{

    static get properties(){return {
        active: {type: Boolean, reflect:true}
    }}

    static listeners = {
        model: {'change:cache change:isViewing': 'requestUpdate'}
    }

    static get styles(){return css`
        :host {
            display: block;
            position:relative;
            position: absolute;
            top: 0;
            left: 0;
            /* background-color: red; */
            width: 1em;
            height: 1em;
            padding: .5em; /* make hit area bigger */
            border-radius: 50%;
            color: var(--theme-bgd);
            background-color: var(--bgd-color, var(--theme-text));
            /* font-size: 1.4em; */
        }

        :host([viewing]) {
            background-color: var(--red-A400);
            color: white;
        }

        [name="sensor"] {
            font-size: 1.4em;
            margin: -.125em;
            /* background: rgba(var(--theme-bgd-rgb), 0.4);
            border-radius: 50%; */
        }

        :host > main  {
            width: 1em;
            height: 1em;
            border-radius: 50%;
            position: relative;
            z-index: 10;

            /* background: rgba(var(--theme-bgd-rgb), 0.4); */
        }

        :host([type="air"]) main {
            color: white;
        }

        :host([active]) main {
            color: white;
            background: var(--red-A400);
        }

        svg {
            fill: currentColor;
        }

        main {
            display: flex;
            justify-content: center;
        }

        main, svg {
            transition: 120ms;
        }

        @media (hover){
        :host(:hover) main {
            transform: scale(1.3)
        }
        }

        .snowdepth {
            position: absolute;
            top: -0.5em;
            left: calc(100% - 1em);
        }

        .airtemp {
            position: absolute;
            bottom: -0.5em;
            left: calc(100% - 1em);
        }
    `}

    constructor(map, data, model){
        super()
        this.map = map
        this.data = data // TEMP
        this.model = model

        // console.log(this.model.get('type'), this);
    }

    firstUpdated(){
        this.addEventListener('click', this.onClick)
    }

    updated(){
        this.toggleAttribute('viewing', !!this.model?.get('isViewing'))
        this.setAttribute('type', this.model.get('type'))

        if( this.model.get('type') == 'air' )
            this.style.setProperty('--bgd-color', this.model.airQualityColor)
        else
            this.style.removeProperty('--bgd-color')
    }

    fetch(...args){
        return fetch(...args).then(r=>r.json())
    }

    onClick(e){
        e.stopPropagation()

        goTo('detail/feature@'+this.model.id)
    }

    get latitude(){
        return this.model.lat
    }

    get longitude(){
        return this.model.lng
    }

    get icon(){
        return {
            snotel: 'sensor',
            resort: 'resort',
            cam: 'cam',
            air: ''
        }[this.model.get('type')] ?? 'sensor'
    }

    render(){return html`
        <main>
        <b-icon name="${this.icon}"></b-icon>

        ${device.isTouch?'':html`
            <b-tooltip label>
                ${this.model.get('name')}

                <b-text sm block align="center">
                ${this.model.get('type')=='air'?html`
                    ${this.model.aqiInfo()?.level}
                `:html`
                    <b-numeral num=${this.model.elevation} format="0,0"></b-numeral> ft
                `}
                </b-text>
            </b-tooltip>
        `}

        ${this.model.get('type')=='air'?html`
            <b-text>${this.model.airQuality}</b-text>
        `:''}

        </main>
        
        ${this.model.get('cache')?.snowDepth
        &&this.model.get('cache')?.snowDepth.val
        &&this.model.cacheAge<=(60*36)?html`
        <b-label filled=${this.model.cacheAge<=60?'theme':'white'} class="snowdepth">
            ${this.model.get('cache')?.snowDepth.val}
            <b-text muted=2 nobold>in</b-text>
        </b-label>
        `:''}

        ${this.model.get('cache')?.airTemp?html`
        <!-- <b-label filled="pink" class="airtemp">
            ${this.model.get('cache')?.airTemp.val}
            <b-text muted=2 nobold>˚</b-text>
        </b-label> -->
        `:''}
    `}

})

export default customElements.get('m-map-marker')