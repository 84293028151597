import { Collection, Model } from 'backbone'
import sync from 'bui/realtime/client/sync'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

class Features extends Collection {

    url = '/api/features'
    get model(){ return Feature}

    toGeoJSON({types}={}){

        let features = this.models.filter(m=>!m.get('parent_id'))

        if( types === undefined )
            types = user.get('settings.features')        

        if( types ){
            if( !Array.isArray(types) )
                types = [types]

            features = features.filter(m=>types.includes(m.get('type')))
        }

        return {
            type: "FeatureCollection",
            crs: { 
                type: "name",
                properties: { name: "mountainreport.app-features" }
            },
            features: features.map(m=>m.toGeoJSON())
        }
    }
}

sync(Features)

export class Feature extends Model {

    urlRoot = '/api/features'

    get lng(){ return this.get('geometry')?.coordinates?.[0] || this.attributes.lng }
    get lat(){ return this.get('geometry')?.coordinates?.[1] || this.attributes.lat }

    get elevation(){ return this.get('props')?.elevation }

    get collections(){ return {
        children: Features
    }}

    // children(){
    //     return FeaturesColl.filter(m=>m.get('parent_id')==this.id)
    // }

    get isImg(){
        // FIXME: hacky
        return this.get('props')?.url || this.get('props')?.cameraId
    }

    get isFeed(){
        return !!this.get('props')?.feed
    }

    get feedUrl(){
        return this.get('props')?.feed
    }

    // minutes
    get cacheAge(){
        let age = this.get('cache')?.ts || 0
        return Math.round((new Date().getTime() - age) / 1000 / 60)
    }

    aqiInfo(){
        if( this.get('type') !== 'air' ) return null

        let aqi = this.get('props')?.AQI

        // TODO: handle no value?
        return AQI.find(d=>aqi <= d.maxVal)
    }

    get airQuality(){ return this.get('props')?.AQI }
    get airQualityColor(){ return this.aqiInfo()?.color || '' }
    get airQualityTS(){ return dayjs.utc(this.get('props')?.ts).local() }

    toGeoJSON(){
        return {
            id: this.id,
            type: "Feature",
            geometry: this.get('geometry'),
            properties: {
                type: this.get('type'),
                name: this.get('name'),
                ...this.get('props')
            }
        }
    }
}

const FeaturesColl = new Features()

export default FeaturesColl


// https://www.airnow.gov/aqi/aqi-basics/
let AQI = [
    {
        color: "#4CAF50",
        level: "Good",
        maxVal: 50,
        detail: "Air quality is satisfactory, and air pollution poses little or no risk."
    },{
        color: "#FFC107",
        level: "Moderate",
        maxVal: 100,
        detail: "Air quality is acceptable. However, there may be a risk for some people, particularly those who are unusually sensitive to air pollution."
    },{
        color: "#e88327",
        level: "Unhealthy for Sensitive Groups",
        maxVal: 150,
        detail: "Members of sensitive groups may experience health effects. The general public is less likely to be affected."
    },{
        color: "#e88327",
        level: "Unhealthy",
        maxVal: 200,
        detail: "Some members of the general public may experience health effects; members of sensitive groups may experience more serious health effects."
    },{
        color: "#a12d57",
        level: "Very Unhealthy",
        maxVal: 300,
        detail: "Health alert: The risk of health effects is increased for everyone."
    },
    {
        color: "#7e224e",
        level: "Hazardous",
        maxVal: Infinity,
        detail: "Health warning of emergency conditions: everyone is more likely to be affected."
    }   
]
