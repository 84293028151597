import store from 'bui/util/store'
import {Collection, Model} from 'backbone'
import {colorScheme} from 'bui/util/color-scheme'
// import realtime from '../lib/realtime'
import sync, {syncBackboneCollection} from 'bui/realtime/client/sync'

export const MapStyles = [
    {label: 'Auto', val: '', description: 'Based on theme'},
    {label: 'Light', val: 'light'},
    {label: 'Dark', val: 'dark'}
]

export const FeatureTypes = [
    {label: 'Show All', val: '', clearsAll: true},
    '-',
    {label: 'Snotel Sensors', val: 'snotel'},
    {label: 'Road Cams', val: 'cam'},
    {label: 'Resorts', val: 'resort'},
    {label: 'USGS Sensors', val: 'usgs'},
    {label: 'Air', val: 'air'},
]

export class User extends Model {

    urlRoot = '/api/user'

    // constructor(){
    //     super(...arguments)

    //     setTimeout(()=>{
    //         // this.applyTheme()
    //         if( this.isMe )
    //             this.on('change:settings', this.applyTheme)
    //     })
    // }

    applyTheme(){
        
        colorScheme.apply({
            colorizeFaviconComposition: 'source-atop',
            theme: (this.get('settings.theme')||'auto'), 
            accent: this.get('settings.theme_accent')||'',
            watch: true
        })
    }

    get models(){ return {
        settings: UserSettings 
    }}

    get uname(){ return this.name }
    get name(){ return this.attributes.name ? this.get('name') : '' }
    get email(){ return this.attributes.email ? this.get('email') : '' }
    get isMe(){ return this === user }

    get initials(){
        return this.name.split(' ').map(s=>s[0]).slice(0,2).join('').toUpperCase()
    }

    logout(){
        store('user', null)
        window.location = '/logout'
    }

    get mapStyle(){
        return this.get('settings').mapStyle
    }

}

// singletons
export const user = new User()
// export const users = new Users()
export default user

class UserSettings extends Model {
    save(...args){
        this.set(...args)
        return this.parentModel.saveSync('settings', this.toJSON(), {patch: true})
    }

    get mapStyle(){
        return this.get('map_style') || colorScheme.isWhatTheme()
    }
}