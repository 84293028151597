import { LitElement, html, css } from 'lit'
import dayjs from 'dayjs'
import SnotelSweGraph from './snotel-swe-graph'
import 'bui/elements/sparkline'
import './trend'
import {median, round, fixOutliers} from 'bui/util/math'

const openSnotelSitePage = (id)=>{
    id = parseInt(id)
    window.open(`https://wcc.sc.egov.usda.gov/nwcc/site?sitenum=`+id)
}

const snotelImg = id=>{
    id = parseInt(id)
    window.open(`https://www.wcc.nrcs.usda.gov/siteimages/${id}.jpg`)
}

customElements.defineShared('s-snotel', class extends LitElement{

    static get styles(){return css`
        :host {
            display: block;
            position:relative;
        }

        .summary {
            display: grid;
            grid-template-columns: repeat(5, 1fr) 2fr;
            border-top: solid 1px var(--theme-bgd-accent);
            border-right: solid 1px var(--theme-bgd-accent);
        }

        .summary > div {
            border-bottom: solid 1px var(--theme-bgd-accent);
            border-left: solid 1px var(--theme-bgd-accent);
            padding: .5rem .25rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
        }

        .summary [large] {
            background-color: var(--theme-bgd-accent2)
        }

        .summary [large] > :first-child {
            font-size: 1.8em;
        }

        /* .summary [large] > :first-child [muted] {
            margin-right: -.5em;
        } */

        .time {
            background-color: var(--theme-bgd-accent);
            font-weight: bold;
            color: var(--theme-text);
        }

        b-sparkline {
            position: absolute;
            bottom: 0;
            /* width: 100%; */
            left: 50%;
            transform: translateX(-50%);
            /* height: 100%; */
            --theme: transparent;
            --fill: rgba(var(--theme-rgb), .3);

        }

        
        .history [num] { color: var(--theme); font-weight: bold; }
        .history [num=""], .history [num="0"] { color: var(--theme-text-accent); font-weight: normal; }
        .history [num*="-"] { color: var(--red); font-weight: normal; }

        @media (max-width:599px) {
            .history {
                grid-template-columns: 1fr 1fr 1fr;
            }
        }
    `}

    // set triplet(triplet){
    //     this.__triplet = triplet
    //     this.update()
    //     this.load(triplet)
    // }

    // get triplet(){ return this.__triplet }

    onModelChange(model){
        if( model )
            this.load(model.triplet)
    }

    async load(){

        if( !this.model ){
            this.data = null
            return this.requestUpdate()
        }

        this.data = await fetch.json(this.model.url()+'/snotel-hourly')
        this.data.date = this.data.date.map(d=>dayjs(d))

        // TODO: improve outlier detection - fails when snowpack was zero and then suddenly increases
        this.snowDepth = this.data.snow_depth.map(v=>v??0) //fixOutliers(this.data.snow_depth, {iqrLower: 3, iqrUpper: 1000})
        this.swe = this.data.snow_water_equivalent.map(v=>v??0) // fixOutliers(this.data.snow_water_equivalent, {iqrLower: 3, iqrUpper: 100})

        // this.temp = lastVal(this.data.air_temperature_observed)
        // this.snowDepth = lastVal(this.data.snow_depth)
        // this.swe = lastVal(this.data.snow_water_equivalent)
        
        this.requestUpdate()
    }

    viewTrend(){
        // let id = this.model.triplet.replace(/:/g, '_')
        goTo(`snotel/trend/${this.model.id}`, {data:this.data})
    }

    render(){return html`
        
        ${this.data?html`

        <b-flex gap=".25">
                
            <b-text lg xbold>
                Snotel Summary
            </b-text>

            <div>
                <b-btn outline color="theme" @click=${this.viewTrend}>14 day trend</b-btn>
            </div>

        </b-flex>

        <b-hr thick></b-hr>

        <b-grid cols=4 gap-row="2" class="history">

            <b-flex col gap=" ">
                <b-text xbold>Current</b-text>
                <b-text lg xbold>${valFromEnd(this.snowDepth, 0)} <b-text muted nobold>in</b-text></b-text>
                <b-text>${valFromEnd(this.data.air_temperature_observed, 0)}˚</b-text>
            </b-flex>

            <b-flex col gap=" ">
                <b-text xbold>12 hours</b-text>
                <b-text lg>
                    <b-num .num=${this.snowDepthLast(12)} format="+0,0"></b-num> <b-text muted>in</b-text>
                </b-text>
                <b-text>${this.sweRatio(12)}<b-text muted>% swe</b-text></b-text>
            </b-flex>

            <b-flex col gap=" ">
                <b-text xbold>24 hours</b-text>
                <b-text lg>
                    <b-num .num=${this.snowDepthLast(24)} format="+0,0"></b-num> <b-text muted>in</b-text>
                </b-text>
                <b-text>${this.sweRatio(24)}<b-text muted>% swe</b-text></b-text>
            </b-flex>


            <b-flex col gap=" ">
                <b-text xbold>Yesterday</b-text>
                <b-text lg>
                    <b-num .num=${this.snowDepthLast(24, {nowHours: 24*1})} format="+0,0"></b-num> <b-text muted>in</b-text>
                </b-text>
                <b-text>${this.sweRatio(24, {nowHours: 24})}<b-text muted>% swe</b-text></b-text>
            </b-flex>

            <b-flex col gap=" ">
                <b-text xbold>2 days ago</b-text>
                <b-text lg>
                    <b-num .num=${this.snowDepthLast(24, {nowHours: 24*2})} format="+0,0"></b-num> <b-text muted>in</b-text>
                </b-text>
                <b-text>${this.sweRatio(24, {nowHours: 24*2})}<b-text muted>% swe</b-text></b-text>
            </b-flex>

            <b-flex col gap=" ">
                <b-text xbold>3 days ago</b-text>
                <b-text lg>
                    <b-num .num=${this.snowDepthLast(24, {nowHours: 24*3})} format="+0,0"></b-num> <b-text muted>in</b-text>
                </b-text>
                <b-text>${this.sweRatio(24, {nowHours: 24*3})}<b-text muted>% swe</b-text></b-text>
            </b-flex>

            <b-flex col gap=" ">
                <b-text xbold>4 days ago</b-text>
                <b-text lg>
                    <b-num .num=${this.snowDepthLast(24, {nowHours: 24*4})} format="+0,0"></b-num> <b-text muted>in</b-text>
                </b-text>
                <b-text>${this.sweRatio(24, {nowHours: 24*4})}<b-text muted>% swe</b-text></b-text>
            </b-flex>

            <b-flex col gap=" ">
                <b-text xbold>5 days ago</b-text>
                <b-text lg>
                    <b-num .num=${this.snowDepthLast(24, {nowHours: 24*5})} format="+0,0"></b-num> <b-text muted>in</b-text>
                </b-text>
                <b-text>${this.sweRatio(24, {nowHours: 24*5})}<b-text muted>% swe</b-text></b-text>
            </b-flex>
            

        </b-grid>

        <br>

        <b-flex>

            <b-text italic muted="some" sm>
                <b-numeral num=${this.model.elevation} format="0,0"></b-numeral> ft
                / 
                <b-text link @click=${this.openPage}>${this.model.triplet}</b-text>
            </b-text>

            <b-btn clear color="theme" @click=${this.viewSWE}>Historical SWE</b-btn>
        </b-flex>

        
        
        `:this.triplet?html`
            <b-empty-state sm static>Fetching snotel data...</b-empty-state>
        `:''}

    `}

    viewSWE(){
        SnotelSweGraph.shared.open(this.model)
    }

    openPage(){
        openSnotelSitePage(this.model.triplet)
    }

    snowDepthLast(hours=24, {nowHours=0}={}){
        let {then, now} = avgValuesBetweenNowAndHoursAgo(this.snowDepth, hours, {nowHours})
        let val = now - then
        return val
        // return val > 0 ? '+'+val : val
    }

    sweLastHours(hours=24, {nowHours=0}={}){
        let {then, now} = avgValuesBetweenNowAndHoursAgo(this.swe, hours, {nowHours})
        return now - then
    }

    sweRatio(hours, {nowHours=0}={}){
        let depth = this.snowDepthLast(hours, {nowHours})
        let swe = this.sweLastHours(hours, {nowHours})

        if( depth <= 0 ) return ''

        return round(swe / depth * 100)
    }

    maxTempLast(hours=24){
        return Math.max(...this.data.air_temperature_observed.slice(-hours))
    }

})

export default customElements.get('s-snotel')

function lastVal(arr){
    return arr[arr.length-1]
}

function valFromEnd(data, endIndex){
    let lastIndex = data.length - 1
    return data[lastIndex - endIndex]
}

function increaseOverHours(data, hours=24){
    let vals = data.slice(-1*hours)
    let val = 0
    // let prevVal = null
    console.log(vals);

    for( let i in vals ){
        
        i = parseInt(i)

        if( hours == 24 )
        console.log(vals[i], vals[i+1]);

        // if( !vals[i+1] ) continue

        // if( vals[i+1] > vals[i] ){

        //     if( prevVal !== null )

        //     prevVal = 
        // }

        if( vals[i+1] && vals[i+1] > vals[i] )
            val += vals[i+1] - vals[i]
    }
    return round(val)
}

function avgValuesBetweenNowAndHoursAgo(data, hours, {nowHours=0}={}){

    hours += nowHours

    // remove outlier data (sometimes snotel values report obvious wrong data...depth had been 80in, suddenly reports 170 for a few hours)
    // data = fixOutliers(data, {iqrLower: 3})

    let lastIndex = data.length - 1
    // average the last few hours
    let then = [
        data[lastIndex - 4 - hours],
        data[lastIndex - 3 - hours],
        data[lastIndex - 1 - hours],
        data[lastIndex - hours],
        data[lastIndex + 1 - hours]
    ]

    // compare to now or an hour ago
    let now = [
        data[lastIndex - 4 - nowHours],
        data[lastIndex - 3 - nowHours],
        data[lastIndex - 2 - nowHours],
        data[lastIndex - 1 - nowHours],
        data[lastIndex - nowHours]
    ]

    // we grabbed many hours incase missing latest data, filter out bad data and get best hours
    now = now.filter(d=>d!==null).slice(-2)
    then = then.filter(d=>d!==null)

    then = median(then)
    now = Math.max(...now)

    return {then, now}
}
