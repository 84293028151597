import Chart, {Plugins, Ticks} from '../weather/chart'
import dayjs from 'dayjs'

customElements.define('s-snotel-chart-temp', class extends Chart{

    get options(){return {
        scales: {
            x: {
                ticks: {
                    callback: Ticks.byHour,
                    font: {size: 14}
                },
            },
            y: {
                // suggestedMin: 10,
            }
        }
    }}

    get plugins(){ return [
        Plugins.addPointLabels({suffix:'˚'})
    ]}

    get chartData(){
        return {
            labels: this.data.date.map(t=>dayjs(t)),
            datasets: [{
                label: 'Temp',
                data: this.data['air_temperature_observed'].map(d=>Math.round(d)),
                borderColor: '#FF1744'
            }]
        }
    }

})
